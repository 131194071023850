export type CmpFn<T> = (o1: T, o2: T) => number

export function comparing<T>(fn: (o: T) => number | string | boolean): CmpFn<T> {
    return (o1, o2) => {
        const n1 = fn(o1)
        const n2 = fn(o2)
        return n1 < n2 ? -1 : n1 > n2 ? 1 : 0
    }
}

export function combinedCmpFn<T>(...fns: CmpFn<T>[]): CmpFn<T> {
    return (o1, o2) => {
        for (const fn of fns) {
            const cmp = fn(o1, o2)
            if (cmp !== 0) {
                return cmp
            }
        }
        return 0
    }
}

export const numberCmp = (n1: number, n2: number) => (n1 < n2 ? -1 : n1 > n2 ? 1 : 0)

export const reverseCmp =
    <T>(cmpFn: CmpFn<T>) =>
    (o1: T, o2: T) =>
        cmpFn(o2, o1)

export const stringCmp = (str1: string, str2: string) => str1.localeCompare(str2)

export const arrayStringCmp = arrayComparator(stringCmp)

// Returns a compare function that orders arrays lexicographically according to the given compare fnuction
export function arrayComparator<T>(cmpFn: CmpFn<T>): CmpFn<T[]> {
    return (arr1: T[], arr2: T[]) => {
        let index = 0
        while (true) {
            if (arr1.length <= index && arr2.length <= index) {
                // Both arrays depleted without difference at lower indexes
                return 0
            }
            if (arr1.length <= index) {
                // Only arr1 depleted
                return -1
            }
            if (arr2.length <= index) {
                // Only arr2 depleted
                return 1
            }
            const cmp = cmpFn(arr1[index], arr2[index])
            if (cmp !== 0) {
                return cmp
            }
            index++
        }
    }
}
