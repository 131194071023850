/* eslint-disable prettier/prettier */
import classNames from 'classnames'
import { IScheduleTransform } from 'common-api'
import _, { max } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useDrop } from 'react-dnd'
import { useDispatch } from 'react-redux'
import type { CourseRoundAccessor, TeacherAccessor } from '../../schedule-access/scheduleAccessWrappers'
import { locallyTriggeredScheduleTransform } from '../../store/schedule/actions'
import { useLocalSchedule } from '../../store/schedule/hooks'
import TypeScale from '../../styles/TypeScale'
import brandColors from '../../styles/colors/brandColors'
import { DndItemTypes } from '../../utils/DndItemTypes'
import { comparing } from '../../utils/compareUtil'
import { toTranslate } from '../../utils/miscUtil'
import styled from '../../utils/styled'
import { DraggableCourseRound } from './CourseRound'
import { DragLayer } from './DragLayer'
import { Positioned } from './Positioned'
import classes from './style.module.css'
import {
    HEADER_WIDTH,
    ROW_HEIGHT,
    TEACHER_FULL_TIME_HOURS,
    hoursToWidth,
    indexToHeight,
    mousePositionToRow
} from './util'

export const TeachingAssignments = () => {
    const dispatch = useDispatch()
    const schedule = useLocalSchedule()

    const dropWrapper = useRef<HTMLDivElement>(null)

    const teachers = schedule.getTeachers().sort(comparing((t) => t.getTeacherSchoolId()))

    const maxVisibleHours =
        1.15 *
        Math.max(
            TEACHER_FULL_TIME_HOURS,
            _.max(teachers.map(teacherCourseRoundsLayout).map((crs) => _.sumBy(crs, (crs) => crs.totalHours))) || 0
        )

    const courseRoundsWithoutTeachers = schedule.getCourseRounds().filter((cr) => cr.getTeachers().length === 0)

    const positionedCrsWithoutTeachers = courseRoundsWithoutTeachers.reduce(
        (acc, cr) => {
            const startHour = acc.length > 0 ? acc[acc.length - 1].startHour + acc[acc.length - 1].totalHours : 0
            const totalHours = hoursToWidth(cr.getTotalHours())

            return [...acc, { cr, startHour, totalHours }]
        },
        [] as { cr: CourseRoundAccessor; startHour: number; totalHours: number }[]
    )

    const [hasOverflow, setHasOverflow] = useState(false)
    const dropboxContentRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const checkOverflow = () => {
            if (dropboxContentRef.current) {
                const hasHorizontalOverflow =
                    dropboxContentRef.current.scrollWidth > dropboxContentRef.current.clientWidth
                setHasOverflow(hasHorizontalOverflow)
            }
        }

        checkOverflow()
        // Add resize observer to check for overflow when container size changes
        const resizeObserver = new ResizeObserver(checkOverflow)
        if (dropboxContentRef.current) {
            resizeObserver.observe(dropboxContentRef.current)
        }

        return () => resizeObserver.disconnect()
    }, [positionedCrsWithoutTeachers])

    function teacherCourseRoundsLayout(
        teacher: TeacherAccessor
    ): { cr: CourseRoundAccessor; startHour: number; totalHours: number }[] {
        const courseRound = schedule
            .getCourseRounds()
            .filter((cr) => cr.getTeachers().some((t) => t.getTeacherId() === teacher.getTeacherId()))
            .sort((a, b) => a.getDisplayName().localeCompare(b.getDisplayName()))

        let start = 0
        return courseRound.map((cr) => {
            const crStart = start
            const crWidth = hoursToWidth(cr.getTotalHours())
            start += hoursToWidth(cr.getTotalHours())

            return { cr, startHour: crStart, totalHours: crWidth }
        })
    }

    const crtws = teachers.map((teacher) => teacherCourseRoundsLayout(teacher))
    const maxByRow = crtws.map((crsInRow) => max(crsInRow.map((crws) => crws.startHour + crws.totalHours)) || 0)

    const [, drop] = useDrop(
        () => ({
            accept: DndItemTypes.EVENT,
            drop: (item: any, monitor) => {
                const currentOffset = monitor.getClientOffset()
                const row = mousePositionToRow(currentOffset, dropWrapper.current?.getBoundingClientRect())

                if (row !== undefined && row >= 0 && row < teachers.length) {
                    const transform = IScheduleTransform.courseRoundTransform({
                        newCourseRound: {
                            ...schedule.findCourseRound(item.courseRoundId).getConjureObject(),
                            teacherIds: [teachers[row].getTeacherId()]
                        }
                    })
                    dispatch(locallyTriggeredScheduleTransform(transform))
                }
            }
        }),
        [teachers]
    )

    const [{ isOver }, dropbox] = useDrop(() => ({
        accept: DndItemTypes.EVENT,
        collect: (monitor) => ({
            isOver: Boolean(monitor.isOver())
        }),
        drop: (item: any) => {
            const transform = IScheduleTransform.courseRoundTransform({
                newCourseRound: {
                    ...schedule.findCourseRound(item.courseRoundId).getConjureObject(),
                    teacherIds: []
                }
            })
            dispatch(locallyTriggeredScheduleTransform(transform))
        }
    }))

    const sortedCourseRounds = crtws
        .flatMap((crsInRow, row) => crsInRow.map((crws) => ({ row, crws })))
        .sort(comparing((crr) => crr.crws.cr.getCourseRoundId()))

    return (
        <>
            <Wrapper ref={drop}>
                {teachers.map((teacher, index) => (
                    <RowWrapper key={teacher.getTeacherId()} index={index}>
                        <TeacherName key={teacher.getTeacherId()} index={index}>
                            <TeacherNameBackground>
                                <div>{teacher.getTeacherSchoolId()}</div>
                                <div style={{ marginTop: '.3em' }}>
                                    {sortedCourseRounds
                                        .filter((cr) => cr.row === index)
                                        .map((cr) => cr.crws.totalHours)
                                        .reduce((prev, current) => prev + current, 0)}
                                    h &nbsp;(
                                    {Math.round(
                                        (100 *
                                            sortedCourseRounds
                                                .filter((cr) => cr.row === index)
                                                .map((cr) => cr.crws.totalHours)
                                                .reduce((prev, current) => prev + current, 0)) /
                                            TEACHER_FULL_TIME_HOURS
                                    )}
                                    %)
                                </div>
                            </TeacherNameBackground>
                        </TeacherName>
                        <HoursWrapper>
                            <Blocked
                                maxVisibleHours={maxVisibleHours}
                                workHours={(TEACHER_FULL_TIME_HOURS * teacher.getWorkPercentage()) / 100}
                            />
                        </HoursWrapper>
                    </RowWrapper>
                ))}
                <CourseRounds>
                    {sortedCourseRounds.map(({ crws, row }) => (
                        <Positioned
                            key={crws.cr.getCourseRoundId()}
                            row={row}
                            startHour={crws.startHour}
                            totalHours={crws.totalHours}
                            maxVisibleHours={maxVisibleHours}
                        >
                            <DraggableCourseRound courseRoundId={crws.cr.getCourseRoundId()} row={row} />
                        </Positioned>
                    ))}
                    <DragLayerWrapper ref={dropWrapper}>
                        <DragLayer maxes={maxByRow} maxVisibleHours={maxVisibleHours} />
                    </DragLayerWrapper>
                </CourseRounds>
            </Wrapper>
            <div
                className={classNames(classes.dropbox, {
                    [classes['dropbox--isOver']]: isOver
                })}
                ref={dropbox}
            >
                <h2 className={TypeScale.Heading_MD}>{toTranslate('Kursomgångar utan lärare')}</h2>
                <div className={classes.dropboxContent} ref={dropboxContentRef}>
                    {positionedCrsWithoutTeachers.length > 0 ? (
                        positionedCrsWithoutTeachers.map((cr) => (
                            <Positioned
                                key={cr.cr.getCourseRoundId()}
                                row={0}
                                startHour={cr.startHour}
                                totalHours={cr.totalHours}
                                maxVisibleHours={maxVisibleHours}
                            >
                                <DraggableCourseRound courseRoundId={cr.cr.getCourseRoundId()} row={0} />
                            </Positioned>
                        ))
                    ) : (
                        <p className={TypeScale.Paragraph_SM_Regular}>
                            {toTranslate('Alla kursomgångar har en lärare kopplat till sig.')}
                        </p>
                    )}
                    {hasOverflow && <div className={classes.gradientRight} />}
                </div>
            </div>
        </>
    )
}

interface RowHeaderProps {
    index: number
}

interface BlockedProps {
    maxVisibleHours: number
    workHours: number
}

const Wrapper = styled('div')`
    position: relative;
    overflow: auto;
    width: 100%;
    height: 100%;
`

const RowWrapper = styled('div')<RowHeaderProps>`
    position: absolute;
    top: ${(props) => indexToHeight(props.index)}px;
    height: ${ROW_HEIGHT}px;
    width: 100%;
    background: ${(props) => (props.index % 2 === 0 ? 'var(--color-grey-300)' : brandColors.white)};
`

const TeacherName = styled('div')<RowHeaderProps>`
    display: flex;
    position: absolute;
    top: 0px;
    height: ${ROW_HEIGHT}px;
    width: ${HEADER_WIDTH};

    align-items: center;
`

const TeacherNameBackground = styled('div')`
    display: table-cell;
    padding: 0.5em;
    font-size: 0.8em;
`

const CourseRounds = styled('div')`
    position: relative;
    height: 100%;
    left: ${HEADER_WIDTH}px;
    width: calc(100% - ${HEADER_WIDTH}px);
`

const HoursWrapper = styled('div')`
    position: relative;
    top: 0;
    left: ${HEADER_WIDTH}px;
    width: calc(100% - ${HEADER_WIDTH}px);
    height: 100%;
`

export const Blocked = styled('div')<BlockedProps>`
    position: absolute;
    opacity: 0.3;
    background: red;

    top: 0;
    left: ${(props) => (100 * props.workHours) / props.maxVisibleHours}%;
    width: ${(props) => 100 * (1 - props.workHours / props.maxVisibleHours)}%;
    height: 100%;
`

const DragLayerWrapper = styled('div')`
    position: absolute;
    pointer-events: none;
    z-index: 100;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
`

const Dropbox = styled('div')<{ isOver: boolean }>`
    position: sticky;
    bottom: 0;
    background-color: white;
    padding: 15px;
    width: 100%;
    overflow: auto;
    height: 10vh;
    z-index: 100;
    border: ${(props) => (props.isOver ? '2px dashed var(--color-grey-900)' : '2px dashed transparent')};
    transition: all 0.3s ease;
`
