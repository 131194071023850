import { Global } from '@emotion/core'
import { ConfigProvider, Result } from 'antd'
import { StrictMode } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Provider } from 'react-redux'
import { Navigate } from 'react-router'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import type { Store } from 'redux'
import AlertProvider from './components/AlertProvider'
import { Root } from './components/layout/Root'
import { CanteenLoadIndexPage } from './pages/canteen-load'
import CourseRoundsIndexPage from './pages/course-rounds'
import CoursesIndexPage from './pages/courses'
import ImportExportIndexPage from './pages/import-export'
import { LecturesIndexPage } from './pages/lectures'
import { PeriodsIndexPage } from './pages/periods'
import { RoomsIndexPage } from './pages/rooms'
import { RoyalScheduleImportIndexPage } from './pages/rs-import'
import ScheduleIndexPage from './pages/schedule'
import { ScheduleParametersIndexPage } from './pages/schedule-parameters'
import SettingsIndexPage from './pages/settings'
import StudentGroupsIndexPage from './pages/student-groups'
import SubjectsIndexPage from './pages/subjects'
import { TeachersPage } from './pages/teachers'
import { TeachingAssignmentIndexPage } from './pages/teaching-assignments'
import type { ApplicationState } from './store'
import { antTweaks } from './styles/ant-tweaks'
import './styles/normalize.css'
import { toTranslate } from './utils/miscUtil'

interface MainProps {
    store: Store<ApplicationState>
}

export const Main = ({ store }: MainProps) => {
    const router = createBrowserRouter([
        {
            path: '/',
            element: <Root />,
            errorElement: (
                <Result
                    title={toTranslate('Försök igen')}
                    subTitle={toTranslate('Vänligen ladda om sidan och försök igen.')}
                />
            ),
            children: [
                { index: true, element: <Navigate to="/schedule" replace /> },
                { path: 'schedule', element: <ScheduleIndexPage /> },
                { path: 'teaching-assignments', element: <TeachingAssignmentIndexPage /> },
                { path: 'student-groups', element: <StudentGroupsIndexPage /> },
                { path: 'teachers', element: <TeachersPage /> },
                { path: 'periods', element: <PeriodsIndexPage /> },
                { path: 'subjects', element: <SubjectsIndexPage /> },
                { path: 'courses', element: <CoursesIndexPage /> },
                { path: 'course-rounds', element: <CourseRoundsIndexPage /> },
                { path: 'lectures', element: <LecturesIndexPage /> },
                { path: 'rooms', element: <RoomsIndexPage /> },
                { path: 'reports/canteen-load', element: <CanteenLoadIndexPage /> },
                { path: 'schedule-parameters', element: <ScheduleParametersIndexPage /> },
                { path: 'import-export', element: <ImportExportIndexPage /> },
                { path: 'rs-import', element: <RoyalScheduleImportIndexPage /> },
                { path: 'settings', element: <SettingsIndexPage /> }
            ]
        }
    ])

    return (
        <StrictMode>
            <Provider store={store}>
                <ConfigProvider
                    theme={{
                        token: {
                            fontFamily: 'Manrope',
                            colorPrimary: '#545eb6',
                            colorInfo: '#545eb6',
                            colorSuccess: '#00762a',
                            colorWarning: '#ff9a2c',
                            colorError: '#ce001c'
                        }
                    }}
                >
                    <AlertProvider>
                        <DndProvider backend={HTML5Backend}>
                            <Global styles={antTweaks} />
                            <RouterProvider router={router} />
                        </DndProvider>
                    </AlertProvider>
                </ConfigProvider>
            </Provider>
        </StrictMode>
    )
}
